<template>
  <div class="search-box" :class="{ 'search-box--is-open': searchBoxOpen }">
    <button @click="openSearchBox" class="tablet:hidden">
      <AtomsImagesIcon icon="magnifying-glass" size="lg tablet:text-xl" />
    </button>
    <div class="flex flex-1 relative bg-neutral-50 transition-all maxmobile:opacity-0 maxmobile:pointer-events-none maxmobile:absolute maxmobile:left-3 maxmobile:right-3" :class="{ 'z-[33] maxmobile:text-base maxmobile:pointer-events-auto maxmobile:opacity-100': searchBoxOpen }">

      <AtomsFormsTextInput id="txtSearch" type="search" v-model="searchText" class="flex-1 !pl-4 !border-0 !bg-neutral-50 text-neutral-500 tablet:!pl-6" :placeholder="searchPlaceholder" ref="searchTextbox" @enter="navigateToSearchResults" @escape="closeSearchBox(true)" @focus="openSearchBox" />

      <MoleculesButtonsButton :show-loader="suggestLoading" :secondary="true" class="block aspect-square maxmobile:w-11 p-0" @click="navigateToSearchResults">
        <AtomsImagesIcon icon="magnifying-glass" size="lg tablet:text-xl" />
      </MoleculesButtonsButton>

      <div v-if="isSuggestOpened" class="search-box__suggest" :class="{ 'search-box__suggest--is-open': isSuggestOpened }">
        <div class="text-right">
          <button @click="closeSearchBox(true)" :title="i18n.t('Zavřít')">
            <AtomsImagesIcon icon="cross" size="xs" class="-mr-2 p-1 !align-top"></AtomsImagesIcon>
          </button>
        </div>

        <div v-if="!suggestLoading && numOfResults === 0 && searchText.length > 2" class="search-box__suggest__no-results" v-html="i18n.t('Pro zadaný dotaz jsme nenašli žádné výsledky. Zkontrolujte, jestli nemáte v zadání překlep, nebo zkuste napsat zadání více obecně. Pokud máte vše v pořádku, pak bohužel nemůžeme sloužit. Zkuste se podívat po něčem jiném, nebo nám napište na <a href=mailto:podpora(at)rigad.cz>podpora(at)rigad.cz</a> ať se domluvíme, co se dá případně dělat.')"></div>
        <div v-if="numOfResults > 0">

          <div v-if="!suggestLoading && didYouMeanPhrase && didYouMeanPhrase !== searchText && !didYouMeanPhrase.includes(searchText)" class="search-box__suggest__did-you-mean" v-html="i18n.t('Zobrazeny výsledky pro <strong>{0}</strong>, jelikož pro zadaný dotaz jsme žádné výsledky nenašli. Zkontrolujte, jestli nemáte v zadání překlep, nebo zkuste napsat zadání více obecně. Pokud máte vše v pořádku, pak bohužel nemůžeme sloužit. Zkuste se podívat po něčem jiném, nebo nám napište na <a href=mailto:podpora(at)rigad.cz>podpora(at)rigad.cz</a> ať se domluvíme, co se dá případně dělat.', [didYouMeanPhrase])"></div>

          <!-- groups -->

          <div v-if="searchResults?.groups?.count" class="search-box__suggest__groups">
            <AtomsGlobalLink v-for="item in searchResults.groups.items.sort((a, b) => a.name.length - b.name.length)" :to="item.page.url" @click="closeSearchBox" class="search-box__suggest__groups__item">{{ item.page.title }}</AtomsGlobalLink>
          </div>

          <!-- vendors -->

          <div v-if="searchResults?.vendors?.count" class="mt-6">
            <div class="mb-8 font-bold text-primary-500">{{ $t('Nalezeno ve značkách') }}</div>
            <div class="search-box__suggest__vendors">
              <AtomsGlobalLink v-for="item in searchResults.vendors.items" :to="item.page.url" @click="closeSearchBox" class="flex flex-col gap-4 underline text-center text-primary-500">
                <AtomsImagesImage :src="`${useShopZone().value.staticResourcesRoot}/img/groups/vendors1/${item.id}.png`" :alt="item.name" class="mx-auto mb-auto" />
                {{ item.name }}
              </AtomsGlobalLink>
            </div>
          </div>

          <!-- wares -->

          <div v-if="searchResults?.wares?.count" class="mt-6">
            <OrganismsListsWareList :items="searchResults?.wares?.items" @click="closeSearchBox" list-name="Našeptávač" />
          </div>

          <div class="button--block mt-6">
            <MoleculesButtonsButton :href="searchUrl" @click="navigateToSearchResults">{{ $t('Zobrazit všechny produkty') }}</MoleculesButtonsButton>
          </div>
        </div>
      </div>
    </div>
    <AtomsGlobalOverlay :custom-z-index="32" :isVisible="searchBoxOpen" @click="closeSearchBox" />
  </div>
</template>
<script setup>

import { useDebounceFn } from '@vueuse/core'
import { useBreakpoints, useElementBounding } from '@vueuse/core';

const appConfig = useAppConfig();
const i18n = useI18n();
const ui = useUI();
const locale = useLocale().getLocale();
const cache = useCache();
const view = useBreakpoints(appConfig.breakpoints);

const searchBoxOpen = useState('isSearchOpen', () => false);

const searchText = ref('');
const searchPlaceholder = ref('');
const searchTextbox = ref(null);
const searchResults = ref({});
const didYouMeanPhrase = ref(null);

const suggestLoading = ref(false);
const isSuggestOpened = ref(false);

let suggestHasBeenOpenedInPast = false;

const numOfResults = computed(() => (searchResults.value.groups?.count ?? 0) + (searchResults.value.wares?.count ?? 0));
const searchUrl = computed(() => searchResults.value.redirectUrl ?? `${cache.getModuleUrl('modules/search')}#!q=${encodeURIComponent(didYouMeanPhrase.value ?? searchText.value)}`);

const openSearchBox = () => {
  searchBoxOpen.value = true;
  onMounted(() => searchTextbox.value.focus());

  if (!suggestHasBeenOpenedInPast) {
    useBaseAnalytics().pushEvent('user_interaction', {
      interaction_name: 'search_start'
    });
  }

  suggestHasBeenOpenedInPast = true;
  //useUI().setBodyScrollable(false);
}

const closeSearchBox = (withAnalytics) => {

  if (withAnalytics) {
    useBaseAnalytics().pushEvent('search', {
      search_term: searchText.value,
      search_type: 'close',
      search_results: searchResults.value.wares.count + searchResults.value.groups.count,
      search_results_products: searchResults.value.wares.count,
      search_results_groups: searchResults.value.groups.count,
    });

    useBaseAnalytics().pushEvent('user_interaction', {
      interaction_name: 'search_close'
    });
  }

  searchBoxOpen.value = false;
  isSuggestOpened.value = false;
  //searchText.value = '';
  searchTextbox.value.blurWithoutValidation();
  //searchText.value = '';

}

let hasNavigated = false;
const navigationRequested = ref(false);
const lastReqId = ref(0);

const suggest = useDebounceFn(() => {
  if (!searchBoxOpen.value) {
    openSearchBox();
  }

  search();
}, 200);

const normalizeString = (q) => {
    return q.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("®", "");
  };

const search = async (didYouMean) => {
  if (searchText.value.trim().length > 2) {

    lastReqId.value++;

    const reqId = unref(lastReqId.value);

    suggestLoading.value = true;

    const tmpSearchResults = (await useApiFetch(`/api/search?phrase=${encodeURIComponent(didYouMean ?? searchText.value)}&take=4&currencyId=${locale.currencyId}&languageId=${locale.languageId}`)).search;

    if (reqId !== lastReqId.value) {
      return;
    }

    if (tmpSearchResults.suggest && tmpSearchResults.wares.count + tmpSearchResults.groups.count === 0) {
      didYouMeanPhrase.value = tmpSearchResults.suggest;
      search(didYouMeanPhrase.value);
      return;
    }

    if (!didYouMean) {
      didYouMeanPhrase.value = null;
    }

    searchResults.value = tmpSearchResults;

    const grpReds = searchResults.value.groups.items.map(q => ({ phrase: normalizeString(q.name), url: q.page.url, phrases: q.page.keywords == null ? [] : normalizeString(q.page.keywords).split(",").map(qq => qq.trim())}));
    const vndReds = searchResults.value.vendors.items.map(q => ({ phrase: normalizeString(q.name), url: q.page.url, phrases: q.page.keywords == null ? [] : normalizeString(q.page.keywords).split(",").map(qq => qq.trim())}));
    const allReds = [...new Set([...grpReds, ...vndReds])];
    const clrPhrase = normalizeString(searchText.value);
    const rdr = allReds.find((q) => q.phrase == clrPhrase || q.phrases.find((qq) => qq == clrPhrase));
    if (rdr !== undefined)
    {
      searchResults.value.redirectUrl = rdr.url;
    }

    useBaseAnalytics().pushEvent('search', {
      search_term: searchText.value,
      search_type: 'suggest',
      search_results: searchResults.value.wares.count + searchResults.value.groups.count,
      search_results_products: searchResults.value.wares.count,
      search_results_groups: searchResults.value.groups.count,
    });

    if (navigationRequested.value) {
      hasNavigated = true;
      useRouter().push(searchUrl.value);

      if (searchResults.value.redirectUrl) {
        useBaseAnalytics().pushEvent('search', {
          search_term: searchText.value,
          search_type: 'direct',
          search_results: searchResults.value.wares.count + searchResults.value.groups.count,
          search_results_products: searchResults.value.wares.count,
          search_results_groups: searchResults.value.groups.count,
        });
      }

      closeSearchBox();
      useUtils().scrollTo(0, 0);
    }

    if (!hasNavigated) {
      isSuggestOpened.value = true;
    }

    suggestLoading.value = false;
  }
  else {
    isSuggestOpened.value = false;
  }
}

const navigateToSearchResults = () => {
  navigationRequested.value = true;
  search();
}

let p_ch = 0;
let p_w = 0;
let p_m = false;
const p_words = i18n.t('Hledáte něco? (s návrhy)').split(';');

const placeholderHints = () => {
  let nextWord = false;
  let fullWorld = false;
  const word = p_words[p_w];

  if (document.activeElement.id === 'txtSearch') {
    p_ch = 0;
    p_w = 0;
    searchPlaceholder.value = word;
  }
  else {
    if (!(p_m == true && p_ch < 0)) {
      searchPlaceholder.value = word.substring(0, p_ch);

      if (p_m) {
        p_ch--;
      }
      else {
        p_ch++;
      }

      if (p_ch > word.length) {
        p_m = true;
        fullWorld = true;
      }
    }
    else {
      p_m = false;
      nextWord = true;
      p_ch = 0;
      p_w++;
      if (p_w >= p_words.length) {
        p_w = 0;
      }
    }
  }

  setTimeout(placeholderHints, Math.floor((Math.random() * (p_m ? 30 : 180)) + (nextWord ? 500 : (fullWorld ? 700 : 30))));
};

if (process.client) {
  placeholderHints();
}

watch(() => searchText.value, (newVal, oldVal) => {
  if (newVal !== oldVal && newVal.trim().length > 2) {
    navigationRequested.value = false;
    didYouMeanPhrase.value = null;
    suggest();
    hasNavigated = false;
  }
});

watch(() => isSuggestOpened.value, () => {
  if (view.smaller('desktop').value) {
    if (isSuggestOpened.value) {
      ui.setBodyScrollable(false);
    }
    else {
      ui.setBodyScrollable(true);
    }
  }
});

</script>
<style lang="postcss">
.search-box {
  @apply flex h-[3.5em] transition-all;

  &__suggest {
    @apply absolute left-0 right-0 top-[100%] z-[31] p-4 max-h-[0] overflow-hidden border-neutral-300 border-t bg-neutral-50 text-neutral-500 opacity-0 tablet:p-6;

    &__groups {
      @apply flex flex-wrap gap-x-4 gap-y-1 justify-center leading-[1.8rem];

      &__item {
        @apply underline text-center text-primary-500;
      }
    }

    &__vendors {
      @apply grid grid-cols-2 gap-12 tablet:grid-cols-4 desktop:grid-cols-6;
    }

    &__no-results,
    &__did-you-mean {

      @apply text-center;

      a {
        @apply text-primary-500;
      }
    }

    &__did-you-mean {
      @apply mb-6;
    }

    &--is-open {

      @screen maxmobile {
        overflow-y: scroll;
        animation: slide-toggle-search-mobile 0.3s;
        animation-fill-mode: forwards;
      }

      @screen tablet {
        overflow-y: scroll;
        animation: slide-toggle-search-tablet 0.3s;
        animation-fill-mode: forwards;
      }

      @screen desktop {
        overflow-y: hidden;
        animation: slide-toggle 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }

  &--is-open {
    @apply tablet:left-[100px] tablet:w-[calc(100%-200px)] desktop:left-[240px] desktop:w-[calc(100%-480px)];
  }
}


@keyframes slide-toggle-search-mobile {
  0% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: calc(100dvh - 68px);
    opacity: 1;
  }
}

@keyframes slide-toggle-search-tablet {
  0% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: calc(100vh - 180px);
    opacity: 1;
  }
}
</style>